import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { ImageObject } from '../../types';
import { Theme, useGlobalState } from '../../context';
import * as classes from './style.module.css';

export interface ArticleCard {
    image?: ImageObject;
    category?: string;
    title: string;
    publishedAt?: Date;
    readingTime?: string;
    link: string;
    isCompany?: boolean;
    brief?: string | null;
}

interface ArticleCardProps {
    data: ArticleCard;
    showBanner?: boolean;
    homePageStyleOverride?: boolean;
}

export function ArticleCard(props: ArticleCardProps): React.ReactElement {
    const { globalState } = useGlobalState();
    const darkModeEnabled = globalState.theme === Theme.Dark;

    // Needed to differentiate between external and internal links (whether or not we use Gatsby Link)
    const absoluteUrl = props.data.link.indexOf('://') > 0 || props.data.link.indexOf('//') === 0;

    const determineStyleOverride = () => {
        let cssStyleResult: object | undefined = {};
        if (darkModeEnabled) {
            cssStyleResult = { border: '0.125rem solid var(--primary-color)'};
        } else if (props.homePageStyleOverride) {
            cssStyleResult = { margin: '1rem 2.5rem 2rem 0.5rem'}
        } else {
            cssStyleResult = undefined;
        }
        return cssStyleResult;
    }

    const articleCard = (
        <article
            className={classes.Card}
            style={determineStyleOverride()}
        >
            {props.showBanner && (
                <div className={props.data.isCompany ? classes.CompanyBanner : classes.Banner}>
                    {props.data.image && props.data.image.src && (
                        <GatsbyImage
                            className={props.data.isCompany ? classes.CompanyImageWrapper : classes.ImageWrapper}
                            imgClassName={classes.Image}
                            image={props.data.image.src.childImageSharp.gatsbyImageData}
                            alt={props.data.image.alt || props.data.title}
                        />
                    )}
                </div>
            )}
            {props.data.isCompany ? (
                <div className={classes.DescriptionWrapper}>
                    <span className={classes.CompanyCategory}>
                            <u>{props.data.title}</u>
                    </span>
                    <div className={classes.CompanyDetails}>
                            {props.data.brief ? props.data.brief : null}
                            {/* {props.data.readingTime && <span className={classes.ReadingTime}>{props.data.readingTime}</span>} */}
                        </div>
                </div>
            ) : 
                <div className={classes.DescriptionWrapper}>
                    {props.data.category ? (
                        <span className={classes.Category}>
                            <u>{props.data.category}</u>
                        </span>
                    ) : null }
                    <h4 className={classes.Title}>{props.data.title}</h4>
                    {props.data.publishedAt ? 
                    (
                        <div className={classes.Details}>
                            {formatDate(props.data.publishedAt)}
                            {props.data.readingTime && <span className={classes.ReadingTime}>{props.data.readingTime}</span>}
                        </div>
                    )
                    : null}
                </div>
            }
        </article>
    );
    

    return absoluteUrl ? (
        <a href={props.data.link} target="_blank" rel="nofollow noopener noreferrer" title={props.data.title}>
            {articleCard}
        </a>
    ) : (
        <Link to={props.data.link} title={props.data.title}>
            {articleCard}
        </Link>
    );
}

export function ArticleCardSkeleton(): React.ReactElement {
    const { globalState } = useGlobalState();
    const darkModeEnabled = globalState.theme === Theme.Dark;
    return (
        <div
            className={classes.Card}
            style={darkModeEnabled ? { border: '0.125rem solid var(--primary-color)' } : undefined}
        >
            <div className={classes.DescriptionWrapper}>
                <SkeletonLoader
                    style={{
                        height: '1.5rem',
                        marginBottom: '.5rem',
                        background: 'var(--tertiary-color)',
                    }}
                />
                <SkeletonLoader style={{ height: '4rem', background: 'var(--tertiary-color)' }} />
                <SkeletonLoader
                    style={{
                        height: '.75rem',
                        width: '50%',
                        marginTop: '.5rem',
                        background: 'var(--tertiary-color)',
                    }}
                />
            </div>
        </div>
    );
}

function formatDate(date: Date): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}
