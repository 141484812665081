// extracted by mini-css-extract-plugin
export var Banner = "style-module--Banner--f56d9";
export var Card = "style-module--Card--98070";
export var Category = "style-module--Category--a7a51";
export var CompanyBanner = "style-module--CompanyBanner--a6c13";
export var CompanyCategory = "style-module--CompanyCategory--bdb3c";
export var CompanyDetails = "style-module--CompanyDetails--1ee28";
export var CompanyImageWrapper = "style-module--CompanyImageWrapper--255a0";
export var CompanyPageWrapper = "style-module--CompanyPageWrapper--02194";
export var CompanyTitle = "style-module--CompanyTitle--7cca2";
export var DescriptionWrapper = "style-module--DescriptionWrapper--cafb3";
export var Details = "style-module--Details--21478";
export var Image = "style-module--Image--66f41";
export var ImageWrapper = "style-module--ImageWrapper--8847f";
export var IndividualCard = "style-module--IndividualCard--865db";
export var ReadingTime = "style-module--ReadingTime--b2a63";
export var SupportCard = "style-module--SupportCard--6c462";
export var SupportTitle = "style-module--SupportTitle--ee577";
export var Title = "style-module--Title--f3999";