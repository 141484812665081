import React from "react";
import { AboutSection } from "../sections/About/index";
import { ArticlesSection } from "../sections/Articles/index";
import { ContactSection } from "../sections/Contact/index";
import { HeroSection } from "../sections/Hero/index";
import { Page } from "../components/Page";
import { Seo } from "../components/Seo/index";
import { ProjectsSection } from "../sections/Projects";

export default function IndexPage() {
  return (
    <>
      <Seo title="Muzdex" />
      <Page>
        <HeroSection sectionId="hero" />
        <ArticlesSection sectionId="companies" heading="Latest Companies Listed" sources={['Company']} />
        <ArticlesSection sectionId="articles" heading="Latest Blog Posts" sources={['Blog']} />
        <AboutSection sectionId="about" heading="What is Muzdex?" />
        {/* <InterestsSection sectionId="details" heading="Details" /> */}
        <ProjectsSection sectionId="features" heading="Why use Muzdex?" />
        <ContactSection sectionId="contact" heading="Queries?" />
      </Page>
    </>
  );
}
