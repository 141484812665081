import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Animation } from '../../components/Animation';
import { Section } from '../../components/Section';
import { SocialProfiles } from '../../components/SocialProfiles';
import { useLocalDataSource } from './data';
import { PageSection } from '../../types';
import * as classes from './style.module.css';
import { Button, ButtonType } from '../../components/Button';

export function ContactSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.allContactJson.sections[0];
    return (
        <Animation type="fadeUp">
            <Section anchor={props.sectionId} heading={props.heading} additionalClasses={[classes.Contact]}>
                {data.description && <p className={classes.Description}>{data.description}</p>}
                <div className={classes.Profile}>
                    <div className={classes.ContactDetails}>
                    <Button
                            type={ButtonType.LINK}
                            externalLink={true}
                            url={`mailto:${data.email}`}
                            label={'Contact Us'}
                    />
                    </div>
                </div>
            </Section>
        </Animation>
    );
}
