import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Animation } from "../../components/Animation";
import { Section } from "../../components/Section";
import { SocialProfiles } from "../../components/SocialProfiles";
import { useLocalDataSource } from "./data";
import { PageSection } from "../../types";
import { Typewriter } from "react-simple-typewriter";
import * as classes from "./style.module.css";
import * as profileClasses from "../../components/SocialProfiles/style.module.css";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { Icon } from "../../components/Icon";

export function HeroSection(props: PageSection): React.ReactElement {
  const response = useLocalDataSource();
  const data = response.allHeroJson.sections[0];
  const { width } = useWindowDimensions() || { width: 500 };
  const handleTypeWritterEffectOverlap = !!(width >= 777);
  const typeWriterEffect = (
    <Typewriter
      words={["Companies", "Services", "Charities", "Apps"]}
      loop={20}
      cursor
      cursorStyle="_"
      typeSpeed={90}
      deleteSpeed={90}
      delaySpeed={2000}
    />
  );
  return (
    <Animation type="fadeUp" delay={400}>
      <Section
        anchor={props.sectionId}
        additionalClasses={[classes.HeroContainer]}
      >
        {/* {data.heroPhoto?.src && (
                    <div className={classes.heroImageCont}>
                        <GatsbyImage
                            className={classes.heroImage}
                            image={data.heroPhoto.src.childImageSharp.gatsbyImageData}
                            alt={data.heroPhoto.alt || `Profile Image`}
                            loading="eager"
                        />
                    </div>
                )} */}
        <div className={classes.Hero}>
          <div className={classes.Intro}>
            {data.intro && (
              <span className={classes.ImagePrefix}>{data.intro}</span>
            )}
            {data.image?.src && (
              <Animation
                className={classes.Image}
                type="waving-hand"
                duration={2500}
                iterationCount={3}
              >
                <GatsbyImage
                  image={data.image.src.childImageSharp.gatsbyImageData}
                  alt={data.image.alt || `Intro Image`}
                  loading="eager"
                />
              </Animation>
            )}
          </div>
          <div>
            <h1 className={classes.Title}>
              We make it easy to support Muslim{" "}
              {handleTypeWritterEffectOverlap ? typeWriterEffect : null}
              <div>
                {!handleTypeWritterEffectOverlap ? typeWriterEffect : null}
              </div>
            </h1>
            {/* <h1 className={classes.Title}>just got easier</h1> */}
          </div>

          {/* <h2 className={classes.Subtitle}>
                        {data.subtitle.prefix}
                        <u>{data.subtitle.highlight}</u>
                        {data.subtitle.suffix}
                    </h2> */}
          {/* <p>{data.description}</p> */}
          <Animation type="fadeLeft" delay={600}>
            {/* {data.socialProfiles && (
              <SocialProfiles
                from={data.socialProfiles.from}
                showIcon={data.socialProfiles.showIcons}
              />
            )} */}
            <a
              className={profileClasses.Profile}
              href={'https://forms.gle/X6xa3KYbG5pm4rwh9'}
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label={'profile.label'}
              style={{ padding: "0.5rem 1.25rem", fontWeight: "bold"}}
            >
                <Icon name={'rocket'} color={'#f87f90'} />
              
              {'Register a company'}
            </a>
            <a
              className={profileClasses.Profile}
              href={'companies'}
              target="_self"
              rel="nofollow noopener noreferrer"
              aria-label={'profile.label'}
              style={{ padding: "0.5rem 1.25rem", fontWeight: "bold"}}
            >
                <Icon name={'search'} color={'#f87f90'} />
              
              {'Discover companies'}
            </a>
          </Animation>
        </div>
      </Section>
    </Animation>
  );
}
